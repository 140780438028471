
.btn {
	.inline-block;
	padding: 1rem 1.5rem;
	border: 0;
	border-radius: 5px;
	font-size: 1.6rem;

	&.pull-right {
		margin-left: .5rem;
	}


	&:hover,
	&:active,
	&:focus,
	&:visited {
		outline: 0;
		color: @terceary-color;
		text-decoration: none;
	}
}

.btn-primary {
	.btn;
	margin: 0 .2rem;
	background: @seventernary-color;
	color: @terceary-color;
	.transition;

	&:hover {
		text-decoration: none;
		color: @terceary-color;
		background: darken(@seventernary-color, 10);
	}
}

.btn-secondary {
	.btn;
	margin: 0 .2rem;
	background: @primary-color;
	color: @terceary-color;

	&:hover {
		text-decoration: none;
		background:  darken(@primary-color, 10);
		color: @terceary-color;
	}
}

.btn-quartenary {
	.btn;
	margin: 0 .2rem;
	background: @quartenary-color;
	color: @terceary-color;

	&:hover {
		text-decoration: none;
		background:  darken(@quartenary-color, 10);
		color: @terceary-color;
	}
}

.btn-fiveternary {
	.btn;
	margin: 0 .2rem;
	background: @fiveternary-color;
	color: @terceary-color;

	&:hover {
		text-decoration: none;
		background:  darken(@fiveternary-color, 10);
		color: @terceary-color;
	}
}

.btn-tenternary {
	.btn;
	margin: 0 .2rem;
	background: @tenternary-color;
	color: @terceary-color;

	&:hover {
		text-decoration: none;
		background:  darken(@tenternary-color, 10);
		color: @terceary-color;
	}
}

.btn-eleventernary {
	.btn;
	margin: 0 .2rem;
	background: @eleventernary-color;
	color: @terceary-color;

	&:hover {
		text-decoration: none;
		background:  darken(@eleventernary-color, 10);
		color: @terceary-color;
	}
}

